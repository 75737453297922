import {CheckoutValidator} from "./classes";
import {States, getHashFragment} from "../sf-utils";

function setHashAndRemoveErrorsOnLoginReg(hash) {
	$(".register-login__form--no-transition").removeClass("register-login__form--no-transition");
	$(".woocommerce-error").remove();
	window.location.hash = hash;
}

export function initCheckoutLoginAndRegister() {
	// REGISTRATION VALIDATION
	new CheckoutValidator(
		"register",
		{
			billing_first_name: ["hasValue"],
			billing_last_name: ["hasValue"],
			username: [
				"hasValue",
				"noEmail",
				"noSpaces",
				"isLowercaseAlphaNumeric",
				"has4Letters",
				"maxLength30"
			],
			email: ["hasValue", "isEmail"],
			password: [
				"hasValue",
				"minLength8",
				"hasCapitalLetter",
				"hasLowercaseLetter",
				"hasNumber",
				"hasSpecialCharacter"
			],
			retype_password: ["hasValue", "matchesDependant"]
		},
		{
			retype_password: "password"
		}
	);

	//LOGIN VALIDATION
	new CheckoutValidator("login", {
		username: ["hasValue", "noSpaces"],
		password: ["hasValue"]
	});

	const loginRegState = new States([
		{
			status: "register",
			// SF TODO: to be brought back in, when checkout login is fixed:
			// getHashFragment() === "register" || getHashFragment() === "login"
			// 	? getHashFragment()
			// 	: "register",
			toggleBetween: ["login", "register"]
		}
	]);

	loginRegState.after(function () {
		setHashAndRemoveErrorsOnLoginReg(loginRegState.state.status);
	});

	$(document.body).on("click", ".woocommerce-error a.showlogin", function (event) {
		setHashAndRemoveErrorsOnLoginReg("login");
		loginRegState.changeAll("login");
	});
}
