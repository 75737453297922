import {EventDelegator} from "./EventDelegator";
import {booleanify} from "../booleanify";
import {check_type} from "../check_type";
import {exists} from "../exists";
import {is_in_array} from "../is_in_array";
import {prepend_to_string} from "../prepend_to_string";
import {set_attr} from "../set_attr";
import {string_to_array} from "../string_to_array";

/**
 * @CLASS that creates and manages manipulatable states between the DOM and JS. These states are easy to control via data-toggle* and data-change* in the DOM.
 * @VERSION 1.6
 * @requires EventDelegator
 * @requires {check_type, exists, prepend_to_string, booleanify, set_attr, string_to_array, is_in_array} from './utils'
 */
export class States {
	constructor(states = ["state"], status, environmental = false, delegateEvents, toggleBetween) {
		states = check_type(states, "array") ? states : [states];
		for (let st of states) {
			st = check_type(st, "string") ? {state: st} : st;
			st.status = exists(st.status) ? st.status : status;
			st.environmental = exists(st.environmental) ? st.environmental : environmental;
			st.delegateEvents = exists(st.delegateEvents) ? st.delegateEvents : delegateEvents;
			st.toggleBetween = exists(st.toggleBetween) ? st.toggleBetween : toggleBetween;
			this.addState(st);
		}

		this.setSwitches(status, environmental, delegateEvents, toggleBetween);
	}

	setSwitches(status, environmental, delegateEvents = true) {
		let buttons = document.querySelectorAll(`[data-switch]`);
		for (const btn of buttons) {
			let state = btn.getAttribute("data-switch");
			state = string_to_array(state, "|")[0];
			if (!this[state]) {
				this.addState({
					state,
					environmental,
					delegateEvents,
					toggleBetween: "switch"
				});
			}
		}
		for (const state in this) {
			if (this[state].switch) {
				new EventDelegator({
					delegators: [
						{
							selector: "#js-app",
							eventType: "click",
							events: {
								switch: (e, d) => {
									const arg = string_to_array(d.switch, "|");
									this.switch(arg[0], arg[1]);
								}
							}
						}
					]
				});
				break;
			}
		}
		if (delegateEvents) {
			new EventDelegator({
				delegators: [
					{
						selector: "#js-app",
						eventType: "click",
						events: {
							switchself: (e, d) => {
								this.switchSelf(e.target, d.switchself);
							}
						}
					}
				]
			});
		}
	}

	switchSelf(target, value) {
		this.switchselfBefore ? this.switchselfBefore(target, value) : false;

		value = !booleanify(value);
		target.setAttribute("data-switchself", value);

		this.switchselfAfter ? this.switchselfAfter(target, value) : false;
	}

	addState({
		state = "state",
		status,
		toggleBetween,
		environmental = true,
		delegateEvents = true
	} = {}) {
		this[state] = !this[state]
			? {
					attribute: prepend_to_string(state),
					environments: this.getEnvironments(environmental, state)
			  }
			: this.throwError("stateAlreadyExists");

		this.setToggleBetween(state, toggleBetween);

		this.setStatus(state, status);

		this.delegateEvents(delegateEvents, state);
	}

	switch(state, index, status) {
		if (!exists(state)) {
			for (const st in this) {
				this.switch(st, index, status);
			}
		} else {
			let current = this[state];
			if (current.switch) {
				if (current.environments && !exists(index)) {
					for (const [i] of current.status.entries()) {
						this.switch(state, i, status);
					}
				} else {
					status = exists(status)
						? status
						: current.environments
						? !current.status[index]
						: !current.status;
					this.changeStatus(state, status, index);
				}
			}
		}
	}

	setToggleBetween(state, toggleBetween) {
		if (toggleBetween) {
			let current = this[state];
			toggleBetween === "switch"
				? (current.switch = true)
				: (current.toggleBetween = toggleBetween);
		}
	}

	delegateEvents(delegateEvents, state, index) {
		const self = this;
		const current = self[state];
		current.events = {
			before: function (func) {
				self.before(state, "toggle change", func);
			},
			after: function (func) {
				self.after(state, "toggle change", func);
			},
			toggle: [this.toggleStatus.bind(this)],
			change: [this.changeStatus.bind(this)]
		};

		if (delegateEvents) {
			if (current.environments && !exists(index)) {
				for (const [i] of current.environments.entries()) {
					this.delegateEvents(delegateEvents, state, i);
				}
			} else {
				new EventDelegator({
					delegators: [
						{
							selector: exists(index) ? this.getEnvironmentTarget(state, index) : "#js-app",
							eventType: "click",
							events: {
								[`change${state}`]: (e, d) => {
									this.fire("change", state, d[`change${state}`], index);
								},
								[`toggle${state}`]: (e, d) => {
									const arg = string_to_array(d[`toggle${state}`], "|");
									this.fire(
										"toggle",
										state,
										current.switch ? current.status : arg[0],
										current.switch ? !current.status : arg[1],
										index
									);
								}
							}
						},
						{
							selector: exists(index) ? this.getEnvironmentTarget(state, index) : "#js-app",
							eventType: "change",
							events: {
								[`select${state}`]: (e, d) => {
									this.fire("change", state, e.target.value, index);
								},
								[`check${state}`]: (e, d) => {
									this.fire("change", state, e.target.checked, index);
								}
							}
						}
					]
				});
			}
		}
	}

	before(func, state = Object.keys(this), events = "toggle change") {
		state = string_to_array(state);
		events = string_to_array(events);

		for (const st of state) {
			if (st === "switchself") {
				this.__proto__.switchselfBefore = func;
				continue;
			}
			const current = this[st];
			for (const ev of events) {
				current.events[ev] = [func, ...current.events[ev]];
			}
		}
	}

	after(func, state = Object.keys(this), events = "toggle change") {
		state = string_to_array(state);
		events = string_to_array(events);

		for (const st of state) {
			if (st === "switchself") {
				this.__proto__.switchselfAfter = func;
				continue;
			}
			const current = this[st];
			for (const ev of events) {
				current.events[ev] = [...current.events[ev], func];
			}
		}
	}

	fire(type, state, ...arg) {
		let current = this[state];
		for (const ev of current.events[type]) {
			if (ev(state, ...arg) === false) {
				break;
			}
		}
	}

	toggleAll(status, toggleBetween, index) {
		for (const state in this) {
			this.toggleStatus(state, status, toggleBetween, index);
		}
	}

	changeAll(status, exceptions, index) {
		exceptions = string_to_array(exceptions);
		for (const state in this) {
			if (!is_in_array(exceptions, state)) {
				this.changeStatus(state, status, index);
			}
		}
	}

	changeStatus(state, status, index) {
		let current = !exists(state)
			? this.throwError("no state argument")
			: !this[state]
			? this.throwError("state doesnt exist")
			: !exists(status)
			? this.throwError("no status to change")
			: this[state];

		if (current) {
			status = booleanify(status, current.switch);
			status = exists(status)
				? status
				: this.throwError("status couldnt be set, set to false", false);
			if (current.environments && !exists(index)) {
				for (const [i] of current.status.entries()) {
					this.changeStatus(state, status, i);
				}
			} else {
				current.environments ? (current.status[index] = status) : (current.status = status);
			}

			set_attr(current.attribute, status, this.getEnvironmentTarget(state, index));
		}
	}

	toggleStatus(state, status, toggleBetween, index) {
		let current = !exists(state)
			? this.throwError("no state argument")
			: !this[state]
			? this.throwError("state doesnt exist")
			: !this[state].switch && !exists(status)
			? this.throwError("no status to change")
			: this[state].switch && exists(status) && !(typeof booleanify(status) === "boolean")
			? this.throwError("status irrelivant disregarded on switch toggle", this[state])
			: this[state].switch &&
			  exists(toggleBetween) &&
			  !(typeof booleanify(toggleBetween) === "boolean")
			? this.throwError("toggleBetween irrelivant disregarded on switch toggle", this[state])
			: this[state];

		if (current) {
			if (current.switch) {
				this.switch(state, index);
			} else if (current.environments && !exists(index)) {
				for (const [i] of current.status.entries()) {
					this.toggleStatus(state, status, toggleBetween, i);
				}
			} else {
				status = {
					current: current.environments ? current.status[index] : current.status,
					new: booleanify(status)
				};
				toggleBetween = exists(toggleBetween)
					? toggleBetween
					: exists(current.toggleBetween)
					? current.toggleBetween
					: exists(current.previous)
					? current.previous
					: current.status;
				toggleBetween = !check_type(toggleBetween, "array object")
					? toggleBetween
					: toggleBetween[index]
					? toggleBetween[index]
					: current.status;

				if (status.new === status.current) {
					this.changeStatus(state, toggleBetween, index);
				} else {
					if (current.environments) {
						current.previous = current.previous ? current.previous : {};
						current.previous[index] = current.status[index];
					} else {
						current.previous = current.status;
					}
					this.changeStatus(state, status.new, index);
				}
			}
		}
	}

	setStatus(state, status = false) {
		let current = this[state];
		if (current.environments) {
			current.status = [];
			for (const [i, env] of current.environments.entries()) {
				let attr = env.getAttribute(current.attribute);
				current.status = [...current.status, exists(attr) ? attr : status];
				this.changeStatus(state, status, i);
			}
		} else {
			const element = document.querySelector(`[${current.attribute}]`);
			const attr = exists(element) ? element.getAttribute(current.attribute) : status;
			current.status = exists(attr) ? attr : status;
			this.changeStatus(state, status);
		}
	}

	getStatus(state, index) {
		if (state) {
			return !this[state]
				? this.throwError("state doesnt exist")
				: this[state].environments && index
				? this[state].status[index]
				: this[state].status;
		} else {
			let obj;
			for (const st in this) {
				obj[st] = this.getStatus(state, index);
			}
			return obj;
		}
	}

	getEnvironments(environmental, state) {
		const environments = document.querySelectorAll(`[data-${state}]`);
		if (environmental) {
			for (const [index, env] of environments.entries()) {
				env.setAttribute("data-stateenvironment", this.getEnvironmentTarget(state, index, false));
			}
		}
		if (!environments.length) {
			document.body.setAttribute(`data-${state}`, "");
		}
		return !environmental || !environments.length ? false : environments;
	}

	getEnvironmentTarget(state, index, incAttr = true) {
		return !exists(index) || !exists(state)
			? false
			: incAttr
			? `[data-stateenvironment=${state}-${index}]`
			: `${state}-${index}`;
	}

	/**
	 * @method throwError - Throws an error with a message
	 * @param {String} message - Message to throw when theres an error.
	 */
	throwError(message, warnAndReturn) {
		if (exists(warnAndReturn)) {
			console.warn(message);
			return warnAndReturn;
		} else {
			throw new Error(message);
		}
	}
}
