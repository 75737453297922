import {exists} from "./exists";

/**
 * @function booleanify changes string/number/undefined/null to boolean if applicable
 * @param {*} item - item to booleanify
 * @param {boolean} returnUndefinedIfNotBooleaned - if not booleanable: will return undefined if this is set to true, else will return item.
 * @returns TODO
 */
export function booleanify(item, returnUndefinedIfNotBooleaned = false) {
	return item === false || item === "false" || item === 0 || !exists(item)
		? false
		: item === true || item === "true" || item === 1
		? true
		: returnUndefinedIfNotBooleaned
		? undefined
		: item;
}
