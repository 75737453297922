export class Modal {
	constructor() {
		this.elements = {
			container: $("#sf-modal"),
			content: this.getContents(),
			download: {
				dropdown: $("[data-downloadlinksdropdown]"),
				dialog: $("[data-downloadlinks]")
			}
		};
		this.ensureOSdetection();
		this.setupEvents();
	}

	ensureOSdetection() {
		console.log("ensureOSdetection");
		const detectedOS = (navigator.userAgentData?.platform || navigator.platform)?.toLowerCase();
		let os = "windows";
		os = detectedOS.startsWith("mac") ? "mac" : os;
		os = detectedOS.startsWith("linux") ? "linux" : os;
		this.elements.download.dialog.each(function () {
			$(this).attr("data-downloadlinks", os);
		});
	}

	setupEvents() {
		const self = this;
		$(document).on("click", "[data-modal]", function (e) {
			e.preventDefault();
			const target = $(this).data("modal");
			// if target is 'close', pass target argument as false to hide modal
			self.show(target == "close" ? false : target);
		});
		$(document).on("click", "[data-closemodal]", function (e) {
			// don't close modal when clicking a child element (use data-modal="close" instead for propagation)
			if (e.target == this) {
				e.preventDefault();
				self.show(false);
			}
		});
		// needed for legacy reasons, and as it exists in the header nav (generated by WordPress, the usual way of using "data-modal" can't be applied):
		$(document).on("click", ".free-download", function (e) {
			e.preventDefault();
			self.show("spider-download");
		});
		$(document).on("click", ".lfa-free-download", function (e) {
			e.preventDefault();
			self.show("lfa-download");
		});

		//download modals
		self.elements.download.dropdown.on("change", function () {
			const val = $(this).val();
			//loop all dropdowns and set as same value as selected:
			self.elements.download.dropdown.each(function () {
				$(this).val(val);
			});

			self.elements.download.dialog.attr("data-downloadlinks", val);
		});

		//team modals:
		$("[data-teamtarget]").on("click", function () {
			const target = $(this).attr("data-teamtarget");
			const member = $(`[data-teammember='${target}']`);
			self.elements.content.team.html(member.html());
		});
	}

	getContents() {
		const obj = {};
		$("#sf-modal [data-modaldialog]").each(function () {
			const item = $(this);
			const name = item.attr("data-modaldialog");
			obj[name] = item;
		});
		return obj;
	}

	show(target) {
		//show modal if target is defined and exists, hide if not:
		target && this.elements.content[target]
			? this.elements.container.show()
			: this.elements.container.hide();
		//loop all content and show if matching target, hide if not (if target is not defined or false, all will be hidden):
		for (let [key, item] of Object.entries(this.elements.content)) {
			key == target ? item.show() : item.hide();
		}
	}
}
