export function initSoftwareNavigation() {
	// ensure redirect to new page when navigtion selection is changed:
	$("select.navigate").on("change", function (e) {
		const select = $(this); // cache the select element
		const option = select.find(`[value="${select.val()}"]`); // find the selected option
		const href = option.attr("data-href"); // get the href attribute of the selected option

		// if the href attribute retrieved, redirect to the href:
		if (href) {
			document.location = href; // redirect to the href
		}
	});
}
