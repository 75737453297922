import {initShowHideBackToTop} from "./initShowHideBackToTop";
import {initCopyableContent} from "./initCopyableContent";
import {initDropdown} from "./initDropdown";
import {initFooterFrog} from "./initFooterFrog";
import {initHeaderNavigation} from "./initMobileNavigation";
import {initHighDPI} from "./initHighDPI";
import {initMaps} from "./initMaps";
import {Modal} from "./initModals";
import {initScrollListeners} from "./initScrollListeners";
import {initSidebarTableOfContents} from "./initSidebarTableOfContents";
import {initTableOfContents} from "./initTableOfContents";
import {initTooltips} from "./initTooltips";
import {initValidationPassword} from "./initValidationPassword";
import {initValidationQuantity} from "./initValidationQuantity";
import {initSoftwareNavigation} from "./initSoftwareNavigation";
import {initAnimations} from "./initAnimations";

export function initGlobal() {
	// HEADER/FOOTER
	initFooterFrog();

	// DROPDOWN
	initDropdown();

	// NAVIGATION
	initHeaderNavigation();
	initSoftwareNavigation();

	// MODALS
	new Modal();

	// TOOLTIPS
	initTooltips();

	//BACK TO TOP
	initShowHideBackToTop();

	// MAPS
	initMaps();

	// SCROLL LISTENERS
	initScrollListeners();

	// TABLE OF CONTENTS (TOC)
	initSidebarTableOfContents();
	initTableOfContents();

	// VALIDATION
	initValidationPassword();
	initValidationQuantity();

	// OTHER
	initCopyableContent(".copy-content");
	initHighDPI();
	initAnimations();

	// SF TODO: is this needed?!?
	// adds active class to menu items when listed in the URL as a hash
	const id = window.location.hash;
	$(id).addClass("active");
}
