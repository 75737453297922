import {initAssignedUserChecker} from "./initAssignedUserChecker";
import {matchLocationPath} from "../sf-utils";

export function initAccount() {
	initAssignedUserChecker();

	// SF TODO: remove when new licences page is live
	if (matchLocationPath("/licences")) {
		$(".woocommerce-MyAccount-navigation-link.is-active").removeClass("is-active");
		$(
			".woocommerce-MyAccount-navigation-link.woocommerce-MyAccount-navigation-link--licences"
		).addClass("is-active");
	}
}
