import {initGlobal} from "./global/init";
import {initMain} from "./main/init";
import {initCheckout} from "./checkout/init";
import {initAccount} from "./account/init";

// import SCSS
import "../scss/app.scss";

// alias jQuery to $ (it's imported by wordpress as "window.jQuery" by default)
window.$ = window.jQuery;

// add custom JQuery functions
(function ($) {
	$.fn.addRmvClass = function (classes, add = true) {
		return this.each(function () {
			add ? $(this).addClass(classes) : $(this).removeClass(classes);
		});
	};
})(jQuery);

(function ($) {
	$.fn.showHide = function (show) {
		return this.each(function () {
			const item = $(this);
			show = show || item.is(":hidden");
			show ? item.show() : item.hide();
		});
	};
})(jQuery);

(function () {
	$(function () {
		initGlobal();
		initMain();
		initCheckout();
		initAccount();

		console.log("Hello from the other side!");
		console.log("SF JS version v4");
	});
})();
