class PasswordChecker {
	constructor(form) {
		this.form = jQuery(form);
		this.currentPasswordInput = jQuery(form.querySelector('[name="password_current"]'));
		//if asking for current password, new password input is optional:
		this.optional = this.currentPasswordInput.length;
		this.validation = {
			input: jQuery(form.querySelector('input[name="password"], [name="password_1"]')),
			inputValue: "",
			warning: {
				container: jQuery(form.querySelector("#password-warning--strength")),
				message: jQuery(form.querySelector("#password-warning--strength p"))
			},
			prompted: false,
			success: false
		};
		this.matched = {
			input: jQuery(form.querySelector('input[name="retype_password"], [name="password_2"]')),
			inputValue: "",
			warning: {
				container: jQuery(form.querySelector("#password-warning--match")),
				message: jQuery(form.querySelector("#password-warning--match p"))
			},
			prompted: false,
			success: false
		};
		this.submitBtn = jQuery(
			form.querySelector(
				'button[name="register"], button[name="save_account_details"], #lost_reset_password__submit'
			)
		);
		this.checks = ["validation", "matched"];

		this.checkAllInputsExist();

		if (this.optional) {
			const self = this;
			this.currentPasswordInput.on("keyup blur", function () {
				self.init(self.currentPasswordInput.val() !== "");
			});
		} else {
			this.init(true);
		}
	}

	init(init = true) {
		const self = this;
		if (init && !this.initiated) {
			this.validation.input.on("keyup focus", function () {
				self.validateInput(this, true);
			});

			this.validation.input.on("blur", function () {
				self.validateInput(this);
				if (self.matched.inputValue !== "") {
					self.checkMatch();
				}
			});

			this.matched.input.on("keyup focus", function () {
				self.checkMatch(this.value, true);
			});

			this.matched.input.on("blur", function () {
				self.checkMatch(this.value);
			});

			this.checkPasswordIncludes();
			this.confirm();
		}
		for (const check of this.checks) {
			this.getInputParent(check);
			if (init) {
				this[check].input.removeAttr("disabled");
				this[check].parent.removeClass("disabled-input-container");
			} else {
				this[check].input.off().attr("disabled", "").val("");
				this[check].parent.addClass("disabled-input-container");
				this.resetPrompt(check);
				this.submitBtn.removeAttr("disabled");
			}
		}
		this.initiated = init;
	}

	checkAllInputsExist() {
		for (const c of this.checks) {
			if (!this[c].input.length) {
				throw new Error(`PasswordChecker: no ${c} input`);
			}
			!this[c].warning.container.length
				? console.warn(`PasswordChecker: no ${c}.warning.container element`)
				: !this[c].warning.message.length
				? console.warn(`PasswordChecker: no ${c}.warning.message element`)
				: true;
		}
		return true;
	}

	checkPasswordIncludes(value = "") {
		return (this.passwordIncludes = {
			capitalLetter: Boolean(value.match(/[A-Z]/g)),
			lowercaseLetter: Boolean(value.match(/[a-z]/g)),
			number: Boolean(value.match(/[0-9]/g)),
			//special characters taken from https://www.computerhope.com/jargon/s/specchar.htm
			// eslint-disable-next-line -- "unnecessary escape character  \["
			specialCharacter: Boolean(value.match(/[~`!@#£$%^&*()_+={}|/:;"'<>,.?\-\[\]\\]/g)),
			EightCharacters: Boolean(value.length >= 8)
		});
	}

	strikeOutIfFulfilled(check, msg) {
		return this.passwordIncludes[check] ? `<span class="strike-out">${msg}</span>` : msg;
	}

	getInputParent(check) {
		return (this[check].parent =
			this[check].parent && this[check].parent.length
				? this[check].parent
				: this[check].input.closest(".woocommerce-form-row"));
	}

	promptIfNecessary(check, message) {
		if (!this[check].warning.container.length || !this[check].warning.message.length) {
			console.warn("PasswordChecker: no message Element to prompt user!");
		}

		this.getInputParent(check);

		if (!this[check].success) {
			this[check].parent
				.removeClass("input-validation--complete")
				.addClass("input-validation--incomplete");
			this[check].warning.message.html(message);
			this[check].warning.container.show();
			this[check].prompted = true;
		} else {
			this[check].parent
				.removeClass("input-validation--incomplete")
				.addClass("input-validation--complete");
			this[check].warning.container.hide();
			this[check].prompted = false;
		}
	}

	resetPrompt(check) {
		this[check].parent.removeClass("input-validation--complete input-validation--incomplete");
		this[check].warning.container.hide();
		this[check].prompted = false;
	}

	validateInput(input, onlyWhenPrompted = false) {
		this.resetPrompt("matched");
		this.checkPasswordIncludes(input.value);
		this.validation.inputValue = input.value;

		this.confirm();

		if (!onlyWhenPrompted || this.validation.prompted) {
			this.promptIfNecessary(
				"validation",
				`The password must:
        <ul>
          <li>${this.strikeOutIfFulfilled(
						"EightCharacters",
						"be more than eight characters long"
					)}</li>
          <li>${this.strikeOutIfFulfilled("capitalLetter", "contain 1 capital letter")}</li>
          <li>${this.strikeOutIfFulfilled("lowercaseLetter", "contain 1 lowercase letter")}</li>
          <li>${this.strikeOutIfFulfilled("number", "contain 1 number")}</li>
          <li>${this.strikeOutIfFulfilled("specialCharacter", "contain 1 special character")}</li>
        </ul>`
			);
		}
	}

	checkMatch(inputValue = false, onlyWhenPrompted = false) {
		// this.matched.inputValue = input ? input.value : this.matched.inputValue;
		this.matched.inputValue = inputValue || this.matched.inputValue;
		this.confirm();

		let message, force, reset;
		if (this.validation.inputValue && this.validation.inputValue !== "") {
			if (this.validation.success) {
				message = "Password and password confirmation do not match";
				force = false;
			} else {
				reset = true;
				message = "Please address issues with the password input first";
				force = true;
			}
		} else {
			reset = true;
			message = "Fill out the password input first";
			force = true;
		}

		if (reset) {
			this.matched.input.val("");
		}

		if (force || !onlyWhenPrompted || this.matched.prompted) {
			this.promptIfNecessary("matched", message);
		}
	}

	confirm() {
		for (const item in this.passwordIncludes) {
			this.validation.success = this.passwordIncludes[item];
			if (!this.validation.success) {
				break;
			}
		}

		this.matched.success =
			this.validation.success && this.matched.inputValue === this.validation.inputValue;

		this.confirmed = this.validation.success && this.matched.success;
		this.confirmed ? this.submitBtn.removeAttr("disabled") : this.submitBtn.attr("disabled", "");
	}
}

export function initValidationPassword() {
	const passwordCheckerForm = document.querySelector(
		".woocommerce-EditAccountForm, .woocommerce-ResetPassword"
	);

	if (passwordCheckerForm) {
		new PasswordChecker(passwordCheckerForm);
	}
}
