/**
 * @function prepend to a string (if not already prepended)
 * @param {string} string - string to prepend to. REQUIRED.
 * @param {string} prepend - the string to be prepended. OPTIONAL: defaults to 'data-' (as this is the most common use case)
 * @param {boolean} append - if true will append the prepend string instead. OPTIONAL: defaults to false;
 * @returns {string} - returns the string with the prepend string prepended (or appended if append is true)
 */
export function prepend_to_string(string, append = false, remove = false, prepend = "data-") {
	return remove
		? string.replace(prepend, "")
		: string.indexOf(prepend) !== -1
		? string
		: append
		? string + prepend
		: prepend + string;
}
