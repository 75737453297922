import {initCart} from "./initCart";
import {initCheckoutLoginAndRegister} from "./initCheckoutLoginAndRegister";
import {initCheckoutBillingDetails} from "./initCheckoutBillingDetails";
import {initSubscriptions} from "./initSubscriptions";
import {matchLocationPath} from "../sf-utils";

export function initCheckout() {
	initCart();

	// if on checkout page
	if (matchLocationPath("/checkout/")) {
		initCheckoutLoginAndRegister();
		initCheckoutBillingDetails();
	}
	// initSubscriptions(); <-- ready to use, but not used anywhere yet
}
