import {APP_ADMIN_AJAX_PATH} from "../../constants";

export class CheckoutVerifier {
	/**
	 * @constructor - Construct the CheckoutVerifier class
	 * @param {class} validator - The validator class instantiation to use for validation
	 */
	constructor(validator) {
		this.validator = validator; // set the validator class as a property for easy reference
		this.fields = this.validator.fields; // set the fields as a property for easy reference
		// set the fields we need responses for:
		this.responses = {
			tax_id: false, // initially set to false
			billing_postcode: false // initially set to false
		};
	}

	/**
	 * @method validateAndVerify - Validate and verify (i.e ajax call for the Tax ID to verify it is an actual Tax ID) the form fields
	 * @param {boolean} processErrors - Whether to process errors or not.
	 * @returns {Promise<boolean>} - Whether the form is valid and the tax ID is verified.
	 */
	async validateAndVerify(processErrors = false) {
		this.validator.form.element.addClass("form-loading"); // add loading class to form to show loading spinner
		let fields = Object.keys(this.validator.fields); // get all fields as an array of names...
		fields = processErrors // ... if processing errors...
			? fields // ... then check all fields ...
			: fields.filter((field) => field !== "tax_id"); // ... else remove the tax_id field from the fields - we don't want to validate and verify (which can take a while) the tax_id field if we're not processing errors

		// validate the fields, then verify the tax ID if the fields are valid:
		const valid = await this.validator.validateFields({
				fields,
				processErrors: false, // we don't want to process errors here, we'll do that later. Just need a quick response to see if the form is valid, to carry on.
				returnOnFirstError: !processErrors // if we're processing errors (later), we want to return on the first error, otherwise we want to check all fields
			}),
			verified = valid ? await this.verifyTaxID() : false;

		this.processErrors(processErrors); // process errors if we're processing errors:
		this.validator.form.element.removeClass("form-loading"); // remove loading class from form
		return valid && verified; // return whether the form is valid and the tax ID is verified
	}

	/**
	 * @method processErrors - Process errors for the form fields
	 * @param {boolean} processErrors - Whether to process errors or not.
	 */
	processErrors(processErrors = true) {
		// if we're processing errors:
		if (processErrors) {
			// loop through each field:
			for (const [name, field] of Object.entries(this.fields)) {
				// process the error log and let us know if there were errors encountered:
				const hasOtherErrors = this.validator.processErrorLogs({
					name, // the name of the field
					recheck: false, // don't recheck the field, just get the first error to appear
					severity: 2 // send error messages of severity 2 (i.e. errors)
				});

				// if there were no other errors for this field, and a response is required for this field, but the response is not successful ...
				if (!hasOtherErrors && this.responses[name] && !this.responses[name].success) {
					this.validator.addMessageToField(name, this.responses[name].msg, 2); // ... then manually add the error message to the field
				}
			}
		}
		// Otherwise, do nothing (not processing errors)
	}

	/**
	 * @method verify - Verify the form fields (I.E Tax ID verification)
	 * @returns {Promise<boolean>} - Whether the tax ID is verified.
	 */
	async verify() {
		let valid = await this.verifyTaxID(); // verify the tax ID and await response
		return valid; // return whether the tax ID is verified once we have a response
	}

	/**
	 * @method verifyTaxID - Verify the Tax ID
	 * @returns {Promise<boolean>} - Whether the tax ID is verified.
	 */
	async verifyTaxID() {
		// return a promise:
		return new Promise((response) => {
			// if the tax ID is not empty ...
			if (this.fields.tax_id.value && this.fields.tax_id.value !== "") {
				// ... Run an AJAX call to verify the tax ID:
				jQuery.ajax({
					type: "POST",
					dataType: "json",
					url: APP_ADMIN_AJAX_PATH,
					data: {
						action: "screamingfrog_wc_check_VAT_validation",
						vat_number: this.fields.tax_id.value,
						billing_country: this.fields.billing_country.value
					},
					success: (data) => {
						const success = data.response === "success";
						// set the response to the responses object 'tax_id' property:
						this.responses.tax_id = {
							success,
							msg: data.message
						};
						// return the success of the response:
						response(success);
					}
				});
			}
			// ... else set the responses to false and return true:
			else {
				this.responses.tax_id = false; // set the responses to false as we've not yet verified the tax ID
				response(true); // return true as the tax ID is not required to be verified yet and we don't want to throw an error.
			}
		});
	}
}
