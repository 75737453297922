import {CheckoutBillingDetails} from "./classes";
import {ResponsiveRearrange, matchLocationPath} from "../sf-utils";

export function initCheckoutBillingDetails() {
	new CheckoutBillingDetails({
		billing_first_name: ["hasValue"],
		billing_last_name: ["hasValue"],
		billing_email: ["hasValue", "isEmail"],
		billing_company: ["hasValue"],
		customer_reference: ["hasValue"],
		billing_country: [],
		billing_address_1: ["hasValue"],
		billing_address_2: ["hasValue"],
		billing_city: ["hasValue"],
		billing_state: ["hasValue"],
		billing_postcode: ["hasValue"],
		tax_id: ["hasCompletedTaxField"]
	});

	if ($("#place_order").length) {
		new ResponsiveRearrange({
			targets: "place_order",
			breakpoints: [{respondToMin: 750, destinations: "checkout-product-details"}]
		});
	}
}
