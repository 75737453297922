import {is_in_array, add_or_remove_from_array} from "../sf-utils";

export function initAdwordsHistory() {
	const adwords = {
		filters: {
			btns: $("[data-adwordsfilter]"),
			active: [],
			mainResetBtn: $("#sf-adwords-history__sidebar-btn--main-reset")
		},
		years: {},
		content: {}
	};

	$("[data-adwordsyear]").each(function () {
		const self = $(this);
		const year = self.attr("data-adwordsyear");
		const content = self.find("[data-adwordscontent]");
		let arrayOfContent = [];
		content.each(function () {
			arrayOfContent.push($(this));
		});
		adwords.content[year] = {
			nocontent: self.find("[data-adwordsnocontent]"),
			items: arrayOfContent
		};
	});

	adwords.filters.btns.on("click", function (e) {
		e.preventDefault();
		const self = $(this);
		const filter = self.data("adwordsfilter");

		//refine filters
		if (filter === "reset") {
			adwords.filters.btns.removeClass("sf-adwords-history__sidebar-btn--active");
			adwords.filters.active = [];
		} else {
			const isNewFilter = !is_in_array(adwords.filters.active, filter);
			self.addRmvClass("sf-adwords-history__sidebar-btn--active", isNewFilter);
			add_or_remove_from_array(adwords.filters.active, filter, isNewFilter);
		}

		//show/hide content
		for (let [year, content] of Object.entries(adwords.content)) {
			let yearHasContent = false;
			for (let c of content.items) {
				const tags = c.attr("data-adwordscontent").split(" ");
				let show = false;
				for (let t of tags) {
					show =
						show || adwords.filters.active.length === 0 || is_in_array(adwords.filters.active, t);
				}
				c.showHide(show);
				yearHasContent = yearHasContent || show;
			}
			content.nocontent.showHide(!yearHasContent);
		}
		adwords.filters.mainResetBtn.addRmvClass(
			"sf-adwords-history__sidebar-btn--active",
			adwords.filters.active.length === 0
		);
	});
}
