import {reference} from "@popperjs/core";
import {delegate} from "tippy.js";
import "tippy.js/animations/shift-away.css";
import "tippy.js/dist/tippy.css";
export function initTooltips() {
	delegate("#js-app", {
		target: "[data-tippy-content]",
		theme: "sf-grey",
		arrow: false,
		offset: [0, 5],
		allowHTML: true,
		animation: "shift-away"
	});
	delegate("#js-app", {
		target: "[data-tippy-green-content]",
		content: (reference) => reference.getAttribute("data-tippy-green-content"),
		theme: "sf-green",
		arrow: false,
		offset: [0, 5],
		allowHTML: true,
		animation: "shift-away"
	});
}
