import {throttle} from "../sf-utils";

export function initShowHideBackToTop() {
	const backToTop = $("#sf-overlay-back-to-top");
	if (backToTop.length) {
		window.onscroll = throttle(function () {
			backToTop.showHide(window.scrollY >= window.innerHeight);
		}, 200);
	}
}
