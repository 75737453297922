import {exists} from "./exists";
import {string_to_array} from "./string_to_array";

/**
 * @function check_type check type of variable
 * @param {*} item - item to check. REQUIRED.
 * @param {string} check - type to check against. OPTIONAL - returns type of item if not passed.
 * @returns {boolean | string} - returns true if item is of type passed, or returns type of item if no type passed.
 */
export function check_type(item, check) {
	const type = Array.isArray(item)
		? "array"
		: typeof item === "object" && item !== null
		? "object"
		: typeof item === "object" && item === null
		? "null"
		: item === undefined
		? "undefined"
		: typeof item;
	let match;
	if (check) {
		for (const c of string_to_array(check)) {
			match = match ? match : type === c;
		}
	}

	return exists(match) ? match : type;
}
