import {States, isSearchPage} from "../sf-utils";

export function initHeaderNavigation() {
	// toggle mobilemenu open states (css used to hide/show depending on state applied to <body> element):
	new States([
		{
			state: "mobilemenu",
			toggleBetween: "switch"
		}
	]);

	// add .expanded-sub-menu class to current menu item and it's ancestors (so it's visible on First Page Load):
	$(
		"#sf-mobile-nav li.menu-item.current-menu-ancestor, #sf-mobile-nav li.menu-item.current-menu-item"
	).addClass("expanded-sub-menu");

	// prevent the click event from bubbling up to the parent element when clicking on a link in the mobile nav menu that has children (i.e. stop the menu from closing when clicking on a link with children):
	$("#sf-mobile-nav li.menu-item.menu-item-has-children a").click(function (e) {
		e.stopPropagation();
	});

	// toggle .expanded-sub-menu on clicked item (if it has children) & remove .expanded-sub-menu class from all other menu items (i.e close all other sub-menus):
	$("#sf-mobile-nav li.menu-item.menu-item-has-children").click(function (e) {
		e.stopPropagation(); // prevent the click event from bubbling up to the parent element
		const self = $(this); // cache the clicked element
		self.find(".expanded-sub-menu").removeClass("expanded-sub-menu"); // remove the .expanded-sub-menu class from any children as a reset
		self.toggleClass("expanded-sub-menu"); // toggle the .expanded-sub-menu class on the clicked element

		// loop all siblings with an .expanded-sub-menu class:
		self.siblings(".expanded-sub-menu").each(function () {
			const sibling = $(this); // cache the sibling
			sibling.removeClass("expanded-sub-menu"); // remove the .expanded-sub-menu class...
			sibling.find(".expanded-sub-menu").removeClass("expanded-sub-menu"); // ...and remove the .expanded-sub-menu class from any children too.
		});
	});
}
